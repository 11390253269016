import React from "react"
import styled from "styled-components"
import VidModal from "../../components/Shared/vidmodal"
import alphaPic from "../../images/alpha.png"
import { motion } from "framer-motion"

const Alpha = () => {
    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.4 }}
        >
            <BannerWrapper>
                <div className="subhead-xl text-color-black">Alpha</div>
                <div className="text-color-black body-text-m">
                Our hope in this semester’s small group is to stir each other to live lives where we continually DEVOTE ourselves to:
            <ul className="list text-color-black margin-top-30">
                                    <li className="list__item black dot hidden-box">
                                        <div className="subhead-xs">The teachings of Jesus & the Apostles</div>
                                    </li>
                                    <li className="list__item black dot hidden-box">
                                        <div className="subhead-xs">Knowing and loving each other well</div>
                                    </li>
                                    <li class="list__item black dot hidden-box">
                                        <div className="subhead-xs anim-slide tr-delay-02">Sitting around a table and eating together </div>
                                    </li>
                                    <li className="list__item black dot hidden-box">
                                        <div className="subhead-xs anim-slide tr-delay-03">Communing with and worshiping God</div>
                                    </li>
                                </ul>
                    <VidModal 
            title=""
            caption="
            That is why this semester we will be delving into the Alpha course, where every week we will have a space to eat and fellowship with one another while discussing some of life's biggest questions."
            link="https://www.youtube.com/embed/MNkn9U_6SFQ"
            pic={alphaPic}
            alt="alpha-video"
            align={0} //0 is align left
            /> 
            <a
                    className="button signup"
                    target="_blank" 
                    rel="noreferrer" 
                    aria-label="smallgroups-sign-up"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdRvIwH3JRngXQ0ezXmdrmGr74ojM06UTkJxdBnMgCaD0rl1Q/viewform"
                    >
                        Sign Up
                    </a>
                </div>
            </BannerWrapper>
        </motion.div>
    )
}

const BannerWrapper = styled.div`
margin: 10%;
position:relative;

.signup{
    position: absolute;
    left: 50%;
    @media only screen and (max-width: 767px){
        left: 20%;
    }
}
`

export default Alpha