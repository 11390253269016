import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Coffee from "../../images/svg/coffee.svg"

const SGBanner = () => {
    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.2 }}
        >
            <BannerWrapper>
                <div className="flex-container small pos-rel">
                    <div className="container padding-top-120 text-color-black pos-rel">
                        <h2 className="headline-xxxxl text-center">
                            SMALL GROUPS
                        </h2>
                        <p className="body-text-m padding-top-60">
                            Small Groups are a great way to get Connected. We meet every week and focus on growing in our walk with God as individuals and as a community. 
                            We have a new curriculum of Small Groups every semester that is focused on challenging us and helping us come to a deeper knowledge of who God is and how to live out our faith.
                        <Coffee className="coffee"/> 
                        </p> 
                    </div>
                </div>
                
            </BannerWrapper>
        </motion.div>
    )
}

const BannerWrapper = styled.div`
margin: 0 10%;

.heading{
    z-index: 1;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.text-center{
    text-align: center;
}

.front-graphic{
    z-index: 0;
    margin-top: 3rem;
    height: auto;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.coffee{
    height: 15rem;
    position: absolute;
    margin: 10%;
    right: 75%;
    filter: invert(.6);

    @media only screen and (max-width: 767px){
        right: 0;
    }
}
`
export default SGBanner