import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Broken from "../../images/svg/shackles.svg"

const SGExplanation = () => {
    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.4 }}
        >
            <BannerWrapper>
                <div className="container padding-top-120 pos-rel text-color-black">
                    <div className="flex-container small smallgroups-flow small text-color-black padding-top-120">
                        <div className="explanation-cell">
                            <h3 className="smallgroups-table_header subhead-xxl">Curriculum &#187;</h3>
                            <ul>
                                <li className="body-text-s">A defined Structure</li>
                                <li className="body-text-s">Weekly Breakdown</li>
                                <li className="body-text-s">A new curriculum every semester</li>
                            </ul>
                        </div>
                        <div className="explanation-cell">
                            <h3 className="smallgroups-table_header subhead-xxl">Groups &#187;</h3>
                            <ul>
                                <li className="body-text-s">Multiple Groups Based on Day and Time</li>
                                <li className="body-text-s">Stay with your groups for the semester</li>
                            </ul>
                        </div>
                        <div className="explanation-cell">
                        <h3 className="smallgroups-table_header subhead-xxl">Commit &#187;</h3>
                            <ul>
                                <li className="body-text-s">Join in on a weekly basis</li>
                                <li className="body-text-s">Tight knit Groups built to ask regular questions</li>
                            </ul>
                        </div>
                        <div className="explanation-cell">
                            <h3 className="smallgroups-table_header subhead-xxl">Grow &#187;</h3>
                            <ul>
                                <li className="body-text-s">Get to know community and God</li>
                            </ul>
                        </div> 
                        <Broken className="broken"/>           
                    </div>
                </div>
            </BannerWrapper>
        </motion.div>
    )
}

const BannerWrapper = styled.div`
margin-top: 5%;

.heading{
    z-index: 1;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.front-graphic{
    z-index: 0;
    margin-top: 3rem;
    height: auto;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.leaders{
    overflow: hidden;
}

.explanation-cell{
    width: auto;
    margin: 0 0 5% 2%;
    @media only screen and (max-width: 767px){
        margin-top: 15%;
        width: 100%;
        margin-left: 15%;
    }
}

.smallgroups-flow{
    flex-direction: right;
    flex-wrap: wrap;
    justify-content: space-around;
    width: calc(100% - 80px);
    height: auto;
}

.broken{
    height: 15rem;
    position: absolute;
    left: 75%;
    bottom: -20%;
    filter: invert(.6);

    @media only screen and (max-width: 850px){
        display: none;
    }
}

`
export default SGExplanation