import React from "react"
import styled from "styled-components"

const SGCard = ({ day, leaders, time, location, signupLink}) => {
    return (
            <BannerWrapper>
                <div className="card">
                    <div className="container padding-top-60 text-color-white pos-rel">
                        <h2 className="subhead-s">
                            {day}
                        </h2>
                        <div className="pad">
                            <h3 className="subhead-s">Led By: {leaders}</h3>
                            <p className="subhead-xxs">{time} <br/>{location}<br/></p>
                        </div>
                        {/* <a target="_blank" 
                            rel="noreferrer" 
                            aria-label="Signup Link"
                            href={signupLink}
                            className=""
                            >
                                Sign up!
                            </a> */}
                    </div>
                </div>
            </BannerWrapper>
    )
}

const BannerWrapper = styled.div`
display: flex;
flex-direction: column;

.pad{
    padding: 15% 0;
}

.card{
    background-color: black;
    border-radius: 25px;
    margin-top: 8%;
    width: 15rem;
    height: 25rem;
    float: left;
    justify-content: space-between;
}
`

export default SGCard