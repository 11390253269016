import * as React from "react"
import Layout from "../components/layout"
import SGBanner from "../components/SmallGroups/sgBanner"
import Leaders from "../components/SmallGroups/leaders"
import SGExplanation from "../components/SmallGroups/sgExplanation"
import Alpha from "../components/SmallGroups/alpha"

const SmallGroupsPage = () => {
  return (
    <Layout>
    <SGBanner />
    <SGExplanation />
    <Alpha/>
    <Leaders />
    </Layout>
  )
}

export default SmallGroupsPage