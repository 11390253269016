import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import SGCard from "./sgcard"

const Leaders = () => {
    const variants = {
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 20 },
      }

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ ease: "easeOut", duration: 0.8, delay: 1.4 }}
        >
            <BannerWrapper>
                <div className="flex-container pos-rel padding-top-120">
                    <div className="container padding-top-bottom-120 text-color-black">
                        <h2 className="subhead-xl adjust-heading"> Our Groups</h2>
                        <div className="leaders flex-container">
                        <SGCard 
                            day="Monday"
                            leaders="Bemnet W & Eyerusalem T"
                            time="7pm-9pm"
                            location=""
                            signupLink="https://docs.google.com/spreadsheets/d/1wxzLXd7QCWO1BxRiBCoIa9CI99E88qNSg3kkdVFnKcg/edit#gid=0"
                        />
                        <SGCard 
                            day="Wednesday"
                            leaders="Lydia T & Belul T"
                            time="6pm-8pm"
                            location=""
                            signupLink="https://docs.google.com/spreadsheets/d/1wxzLXd7QCWO1BxRiBCoIa9CI99E88qNSg3kkdVFnKcg/edit#gid=0"
                        />
                        <SGCard 
                            day="Thursday"
                            leaders="Leah E"
                            time="6pm-8pm"
                            location=""
                            signupLink="https://docs.google.com/spreadsheets/d/1wxzLXd7QCWO1BxRiBCoIa9CI99E88qNSg3kkdVFnKcg/edit#gid=0"
                        />
                        </div>
                    </div>
                </div>
            </BannerWrapper>
        </motion.div>
    )
}

const BannerWrapper = styled.div`
.heading{
    z-index: 1;
    text-align: center;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
.front-graphic{
    z-index: 0;
    margin-top: 3rem;
    height: auto;
    width: 100%;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.leaders{
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    @media only screen and (max-width: 767px){
        margin-left: 15%;
    }
}
.adjust-heading{
    @media only screen and (max-width: 767px){
        margin-left: 10%;
    }
}
`
export default Leaders